import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo'
import useFirestore from '../../modules/flamelink-module';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import _ from 'lodash';

import homebanner from '../../images/landing/bg3.png';
import Play from '../../images/icons/Play.png';
import bgcircle from '../../images/landing/bgcircle.svg';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';
import ImageAnnotate from '../../images/landing/photo.svg';
import wifi from '../../images/landing/wifi.svg';
import Text from '../../images/landing/text.svg';
import { UNBIASED_VIDEO, CONTACT_US } from '../../config/constants';
import ContactModal from '../../components/ContactModal';

import Userbackground from '../../images/landing/bg2.jpg';

import './datacollection_styles.scss'


export default function DataCollection() {
    const docs = useFirestore('fl_content', 'datacollection');
    const [open, setOpen] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenVideo = () => {
        setOpenVideo(true);
    };

    const handleCloseVideo = () => {
        setOpenVideo(false);
    };

    return (
        <Layout>
            <SEO title="Data Collection" description="" />
            <ContactModal open={open} handleClose={handleClose} />
            <ContactModal open={openVideo} handleClose={handleCloseVideo} source={UNBIASED_VIDEO} />
            <div className="dataCollection">
                <section className="main-banner">
                    <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                    <Container>
                        <div className="collection-banner">
                            <Grid container direction="column">
                                <Grid item xs={12} sm={12} md={6}>
                                    <h2>{!_.isEmpty(docs) && docs[0].title}</h2>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Grid container >
                                        <Grid item>
                                            <Button onClick={handleOpenVideo} target="_blank" className="btnActive"><img src={Play} alt="Play" width="20" /> What's Unbiased?</Button>
                                        </Grid>
                                        <Grid item className="btContact">
                                            <Button onClick={handleOpen} target="_blank" className="btnInActive">Contacts Us</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </section>

                <section className="servicesCont">
                    <Container>
                        <Grid container alignItems="center">
                            {!_.isEmpty(docs) && docs[0].hasOwnProperty("collection") && (
                                <Grid item xs={12} sm={12} md={6}>
                                    <h2>{docs[0].collection[0].title}</h2>
                                    <p>{docs[0].collection[0].description}</p>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={6}>
                                <ul>
                                    <li>
                                        <Link><img src={ImageAnnotate} width="30" alt="Annotation" /></Link>
                                    </li>
                                    <li>
                                        <Link rel="noreferrer"><img src={Text} width="30" alt="Annotation" /></Link>
                                    </li>
                                    <li>
                                        <Link rel="noreferrer"><img src={wifi} width="30" alt="Annotation" /></Link>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                {!_.isEmpty(docs) && docs[0].hasOwnProperty("collection") && (
                    <section>
                        <Container>
                            <div className="gridSec">
                                <div className="dataCont">
                                    <h4><img src={docs[0].collection[1].iconlink} width="30" alt="Icon" />{docs[0].collection[1].title}</h4>
                                    <p>{docs[0].collection[1].description}</p>
                                </div>
                                <div className="imgCont">
                                    <img src={docs[0].collection[1].imagelink} alt="Banner" />
                                </div>
                            </div>
                        </Container>
                    </section>
                )}

                {!_.isEmpty(docs) && docs[0].hasOwnProperty("collection") && (
                    <section>
                        <Container>
                            <div className="gridSec">
                                <div className="imgCont">
                                    <img src={docs[0].collection[2].imagelink} alt="Banner" />
                                </div>
                                <div className="dataCont">
                                    <h4><img src={docs[0].collection[2].iconlink} width="30" alt="Icon" />{docs[0].collection[2].title}</h4>
                                    <p>{docs[0].collection[2].description}</p>
                                </div>
                            </div>
                        </Container>
                    </section>
                )}

                {!_.isEmpty(docs) && docs[0].hasOwnProperty("collection") && (
                    <section>
                        <Container>
                            <div className="gridSec">
                                <div className="dataCont">
                                    <h4><img src={docs[0].collection[3].iconlink} alt="Icon" width="26" />{docs[0].collection[3].title}</h4>
                                    <p>{docs[0].collection[3].description}</p>
                                </div>
                                <div className="imgCont">
                                    <img src={docs[0].collection[3].imagelink} alt="Banner" />
                                </div>
                            </div>
                        </Container>
                    </section>
                )}

                <section className="top-usecases">
                    <Container>
                        <h2>{!_.isEmpty(docs) && docs[0].usecasetitle}</h2>
                        <Grid container>
                            {
                                !_.isEmpty(docs) && docs[0].hasOwnProperty("usecase") && docs[0].usecase.map((val, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className="box">
                                                <img src={val.location} alt="thumbnail" />
                                                <h4>{val.title}</h4>
                                                <p>{val.summary}</p>
                                                <Link>LEARN MORE</Link>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                </section>


                <section className="whyChoose">
                    <img src={bgcircle} alt="Background" className="bgCircle" />
                    <section container className="why-choose-cont">
                        <h2>Why choose unbiased?</h2>
                        <p>Unbiased is innovating to solve many of the current problems in the Artifical Intelligence & Machine Learning industry such as Transparency, Bias and Quality of Training Data. Unbiased Data Marketplace platform will act as a gateway to Data Annotations, knowledge sharing, collaborations & futuristic innovation.</p>

                        <div className="circleContLarge">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={transparency} alt="Transparency" width="36" />
                                </div>
                                <h6>TRANSPARENCY</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={quality} alt="Quality Assurance" width="36" />
                                </div>
                                <h6>QUALITY ASSURANCE</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                                </div>
                                <h6>UNBIASED DATA</h6>
                            </div>
                        </div>

                        <div className="circleContSmall">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                                </div>
                                <h6>WORKER SATISFACTION</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={money} alt="Spam Free Accounts" width="36" />
                                </div>
                                <h6>SPAM FREE ACCOUNTS</h6>
                            </div>
                        </div>


                        <Grid container justify="center" className="btn-cont">
                            <Button onClick={handleOpenVideo} target="_blank" className="btn-solid"><img src={Play} alt="play" width="20" /> What’s unbiased?</Button>
                            <Button className="btn-bordered">LEARN MORE</Button>
                        </Grid>
                    </section>
                </section>

                <section className="happy-users">
                    <img src={Userbackground} alt="Background" className="happy-user-bg" />
                    <Grid container direction="column" alignItems="center" justify="center" className="formCont">
                        <h2>JOIN THE LIST OF OUR HAPPY USERS</h2>
                        <Button onClick={handleOpenVideo} target="_blank">
                            <img src={Play} alt="play" width="20" />
                            What’s unbiased?
                        </Button>
                    </Grid>
                </section>
            </div>
        </Layout>
    );
}
